<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>Profile</h5>
				<p>This is your empty page template to start building beautiful applications.</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
